import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };
  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN ALABAMA' activeCard='alabama' image={'/alabama-bg.jpg'}>
          <p>
            We’re here to help you get affordable health insurance in Alabama. We Offer Every Solution Available In Your Market To Give You The Highest Quality
            Insurance At The Lowest Possible Price. Apollo Provides Education, Options And The Best Pricing Available In Your Market. We Look Forward To Working
            With You!
          </p>
          <p>
            Prior to 2014, individual coverage was much cheaper than group coverage because pre-existing conditions were difficult to get covered and only a few
            individual plans covered the essential health benefits. However, all that changed in 2014 when the Affordable Care Act was enacted, which required
            all health insurance companies in Alabama and around the country to cover the essential benefits.
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance in Missouri shifting to the consumer, gap insurance plans have become the most important and popular plan
            we sell today.
          </p>

          <div className='licensed-in-why-choose'>
            <span>WHY CHOOSE INDIVIDUAL HEALTH INSURANCE IN ALABAMA?</span>

            <p>
              There are many reasons why choosing individual health insurance is beneficial. The first is that you can select the policy the best fits your
              circumstance in life. It is great for those with little income because it’s a cheaper option and you still get all essential health benefits
              covered. Another reason that makes individual coverage great is that you can still keep your policy if you switch jobs. Let’s talk today about
              getting you affordable health insurance in Alabama.
            </p>
          </div>
        </Content>
      </div>
    </Layout>
  );
};
